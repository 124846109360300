<template>
  <div class="post-appearance">
    <div class="post-appearance__content" :class="raw.type === 'photo' ? 'top' : 'bottom'">
      <span class="post-appearance__text" v-if="raw.text" v-html="raw.text.replace(/\n/g, '<br>')"/>
      <div
        v-if="raw.photo_url"
        class="post-appearance__image-preview"
        :class="raw.type === 'photo' ? 'top' : 'bottom'">
        <div class="post-appearance__image-preview-content-wrapper">
          <div :style="{'padding-top': `${ratio*100}%`}" v-if="!isLoaded" class="w-0"/>
          <img
            loading="lazy"
            @load="handleLoad"
            ref="image"
            v-if="['image', 'gif'].includes(mediaType)"
            :src="mediaUrl"
            class="post-appearance__image-preview-content"
            alt="Image"/>
          <video
            @load="handleLoad"
            autoplay
            muted
            loop
            controls
            v-else-if="mediaType === 'video'"
            :src="mediaUrl"
            class="post-appearance__image-preview-content"
            alt="Video"/>
        </div>
      </div>

    </div>
    <div class="post-appearance__buttons" v-if="raw.buttons && raw.buttons.length > 0">
      <div class="post-appearance__buttons-row" v-for="row in raw.buttons">
        <div class="post-appearance__button" v-for="button in row">
          {{button.text}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    getWebUrl,
    getMediaType,
  } from '@/functions/media'

  export default {
    name: 'PostAppearance',
    props: {
      post: Object,
    },
    data: () => ({
      naturalWidth: 100,
      naturalHeight: 60,

      isLoaded: false,
    }),
    methods: {
      handleLoad() {
        this.isLoaded = true
      }
    },
    computed: {
      raw() {
        return this.post.raw || {}
      },
      mediaUrl() {
        return this.raw.photo_url && getWebUrl(this.raw.photo_url)
      },
      mediaType() {
        return getMediaType(this.raw.photo_url)
      },
      ratio() {
        return this.naturalHeight / this.naturalWidth
      },
    },
    mounted() {
      const maxIterations = 15000
      let i = 0
      const checkNaturalSize = () => {
        const ref = this.$refs.image
        if (!ref) {
          return
        }
        if (ref.naturalWidth) {
          this.naturalWidth = ref.naturalWidth
          this.naturalHeight = ref.naturalHeight
          return
        }
        i += 1
        if (i >= maxIterations) {
          console.log('exiting checkNaturalSize (maxIterations)')
          return
        }
        setTimeout(checkNaturalSize, 10)
      }

      if (this.raw.photo_url) {
        // console.log('image detected')
        checkNaturalSize()
      }
    },
  }
</script>

<style scoped lang="stylus">
  .post-appearance
    @apply flex flex-col

  .post-appearance__content
    @apply flex flex-col
    @apply bg-white rounded border border-gray-300
    @apply overflow-hidden
    max-width 600px

    &.top
      @apply flex-col-reverse

      .post-appearance__text
        @apply mx-2 my-1

    &.bottom
      @apply py-2

      .post-appearance__text
        @apply mx-3

      .post-appearance__image-preview
        @apply mx-3 mt-2 mb-1

  .post-appearance__text
    @apply mx-2 text-gray-800
    word-break break-word

  .post-appearance__image-preview
    @apply relative

    &.bottom
      .post-appearance__image-preview-content-wrapper
        @apply ml-3

      .post-appearance__image-preview-content
        @apply rounded

      &:before
        content ''
        position absolute
        width 2px
        top 0
        bottom 0
        background-color #409EFF
        border-radius 1.5px
        align-self stretch

  .post-appearance__image-preview-content-wrapper
    display flex
    flex-direction row
    align-items center
    @apply rounded bg-gray-100

  .post-appearance__image-preview-content
    @apply w-full

  .post-appearance__buttons
    @apply flex flex-col

    max-width 600px

    .post-appearance__buttons-row
      @apply flex flex-row mt-1

  .post-appearance__button
    @apply flex-1 p-2 border border-gray-300 rounded font-bold text-center text-sm text-gray-700
    @apply overflow-hidden whitespace-no-wrap
    @apply bg-white

    &:not(:first-child)
      @apply ml-1
</style>
