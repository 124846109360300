<template>
  <component
    :is="el"
    class="blinkable"
    :class="[{blink: isBlinking}, blinkClass]"
    @animationend.self="handleAnimationend">
    <slot/>
  </component>
</template>

<script>
  export default {
    name: 'Blinkable',
    props: {
      el: {
        type: String,
        // todo element name validation
        default: 'div'
      }
    },
    data: () => ({
      isBlinking: false,
      blinkClass: 'blink-white'
    }),
    methods: {
      blink(blinkClass = 'blink-white') {
        this.isBlinking = false
        this.$nextTick(() => {
          this.blinkClass = blinkClass
          this.isBlinking = true
        })
      },

      handleAnimationend() {
        this.isBlinking = false
      }
    }
  }
</script>

<style scoped lang="stylus">
  .blinkable
    position relative

    &:before
      content ''
      position absolute
      z-index 1000
      top 0
      bottom 0
      left 0
      opacity 0

    &.blink
      animation .5s noop

      &:before
        animation .5s blink

    &.blink-white:before
      @apply bg-white
      animation-duration .5s

    &.blink-error:before
      @apply bg-red-200
      animation-duration 1s

  @keyframes noop
    0% {
    }
    100% {
    }

  @keyframes blink
    0%
      right 0
      opacity 1
    100%
      right 0
      opacity 0
</style>