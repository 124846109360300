<template>
  <div class="post">
    <helmet>
      <title>{{$t('title.postView')}}</title>
    </helmet>
    <TitleHeader :backTo="`/channel/${channel.cid}`">{{$t('postView.post')}}</TitleHeader>
    <div class="post__content">
      <div class="post__cards" v-if="post">
        <card class="appearance" no-border>
          <post-appearance :post="post"/>
        </card>
        <card class="state">
          <CardHeader :title="$t('postView.labels.info')">
            <el-popover
              trigger="click">
              <button
                slot="reference"
                class="button w-full">
                {{$t('post.actions.export')}}
              </button>
              <div class="flex flex-col items-stretch">
                <pre class="post__export-preview">{{JSON.stringify(this.post)}}</pre>
                <button
                  class="button button_type_filled"
                  @click="exportPostCopy">
                  {{$t('post.export.actions.copy')}}
                </button>
              </div>
            </el-popover>
          </CardHeader>
          <table class="state-table table">
            <tbody>
            <tr>
              <td>{{$t('postView.info.labels.type')}}</td>
              <td>
                <template v-if="post.type === 'scheduled'">
                  <el-icon name="timer"/>
                  {{$t('postView.info.types.scheduled')}}
                </template>
                <template v-else>{{$t('postView.info.types.instant')}}</template>
              </td>
            </tr>
            <tr v-if="post.type === 'scheduled'">
              <td>{{$t('postView.info.labels.scheduledDate')}}</td>
              <td>
                {{formatDate(new Date(post.date || 0))}}
              </td>
            </tr>
            <tr>
              <td>{{$t('postView.info.labels.status')}}</td>
              <td>
                <template v-if="post.status === 'sent'">
                  <el-icon name="check"/>
                  {{$t('post.statuses.sent')}}
                </template>
                <template v-else>
                  <el-icon name="timer"/>
                  {{$t('post.statuses.scheduled')}}
                </template>
              </td>
            </tr>
            <tr>
              <td>{{$t('postView.info.labels.creationDate')}}</td>
              <td>
                {{formatDate(new Date(post.created_at || 0))}}
              </td>
            </tr>
            <!--          <tr>-->
            <!--            <td>Open</td>-->
            <!--            <td>-->
            <!--              <span-->
            <!--                class="blinkable"-->
            <!--                :class="{blink: closedTextBlink}"-->
            <!--                @animationend.self="closedTextBlink = false">-->
            <!--                <el-icon :name="toggleCloseIcon"/>-->
            <!--                {{post.is_closed ? 'No.' : 'Yes.'}}-->
            <!--              </span>-->
            <!--              <el-button-->
            <!--                type="text"-->
            <!--                @click="toggleClose"-->
            <!--                class="toggle-button">-->
            <!--                {{post.is_closed ? 'open' : 'close'}}-->
            <!--              </el-button>-->
            <!--            </td>-->
            <!--          </tr>-->
            </tbody>
          </table>
        </card>
        <card class="basic-stats" v-if="post && post.buttons_flatten">
          <card-header :title="$t('postView.labels.answers')">
            <button
              class="button"
              @click="updateBasicStats"
              :class="{'button_loading': basicStatsLoading}">
              {{$t('postView.answers.actions.update')}}
            </button>
          </card-header>
          <div class="overlay" v-if="post.status !== 'sent'">
            <span>{{$t('postView.answers.postNotSent')}}</span>
          </div>
          <blinkable
            v-else
            el="table"
            class="table"
            ref="basicStatsBlink">
            <tbody>
            <tr v-for="(text, i) in buttonTexts">
              <td>{{text}}</td>
              <td>{{counts[i] || 0}}</td>
            </tr>
            <tr>
              <td>{{$t('postView.answers.total')}}</td>
              <td>{{totalCount}}</td>
            </tr>
            </tbody>
          </blinkable>
        </card>
      </div>

    </div>
  </div>
</template>

<script>
  import Helmet from '@/components/Helmet.vue'
  import TitleHeader from '@/components/TitleHeader.vue'
  import Card from '@/components/Card.vue'
  import CardHeader from '@/components/CardHeader.vue'
  import PostAppearance from '@/components/PostAppearance.vue'
  import Blinkable from '@/components/Blinkable.vue'

  import dateFnsLocaleMixin from '@/mixins/dateFnsLocale'

  import store from '@/store'
  import {chain} from '@/functions/utils'
  import {
    ensureCheckAuth,
    ensureGetChannels,
    ensureGetUser,
    getPost,
    getBasicStats,
  } from '@/functions/data'

  import {
    apiOpenPost,
    apiClosePost,
    apiGetBasicStats,
  } from '@/functions/api'

  import {
    format,
    utcToZonedTime,
  } from 'date-fns-tz'
  import copy from 'copy-to-clipboard'
  import {showSuccessSnackbar} from '@/functions/snackbar'

  export default {
    name: 'PostView',
    mixins: [
      dateFnsLocaleMixin,
    ],
    components: {
      Helmet,
      TitleHeader,
      Card,
      CardHeader,
      PostAppearance,
      Blinkable,
    },
    data: () => ({
      post: {},
      counts: [],
      totalCount: 0,

      closedTextBlink: false,

      toggleCloseButtonLoading: false,
      basicStatsLoading: false,
    }),
    methods: {
      applyPost(post) {
        this.post = post
      },
      applyBasicStats(stats) {
        this.totalCount = stats.total_count
        this.counts = stats.counts
      },

      formatDate(date) {
        const timeZone = this.channelTimezone
        return format(
          utcToZonedTime(date, timeZone),
          this.$t('common.dateTimeLong'),
          {locale: this.dateFnsLocale, timeZone},
        )
      },

      // async toggleClose() {
      //   if (this.toggleCloseButtonLoading) {
      //     return
      //   }
      //
      //   this.toggleCloseButtonLoading = true
      //
      //   const result = this.post.is_closed
      //     ? await apiOpenPost(this.post.id)
      //     : await apiClosePost(this.post.id)
      //
      //   this.toggleCloseButtonLoading = false
      //
      //   if (result.ok) {
      //     this.closedTextBlink = true
      //     this.post.is_closed = result.result.is_closed
      //   }
      // },

      async updateBasicStats() {
        if (this.basicStatsLoading) {
          return
        }

        this.basicStatsLoading = true

        const result = await apiGetBasicStats(this.post.id)

        this.basicStatsLoading = false

        if (result.ok) {
          this.$refs.basicStatsBlink.blink()
          this.applyBasicStats(result.result)
          return true
        }

        return this.$notify.error({
          title: 'Error',
          message: 'This is an error message',
        })
      },

      async exportPostCopy() {
        await copy(JSON.stringify(this.post.raw))
        showSuccessSnackbar(this.$t(`post.export.copied`))
      },
    },
    computed: {
      toggleCloseIcon() {
        if (this.toggleCloseButtonLoading) {
          return 'loading'
        }
        if (!this.post.is_closed) {
          return 'check'
        }
        return 'close'
      },

      channels() {
        return this.$store.state.auth.userChannels
      },
      channel: {
        get() {
          return this.post && this.post.cid && this.channels.find(c => c.cid === this.post.cid) || {}
        },
        set(v) {
          this.$store.commit('auth/setChannel', v)
        },
      },

      channelTimezone() {
        return this.channel && this.channel.settings && this.channel.settings.timezone_id || 'Etc/GMT'
      },

      buttonTexts() {
        return this.post
          && this.post.buttons_flatten
          && this.post.buttons_flatten
            .filter((button) => button.type === 'answer')
            .map((button) => button.text)
          || []
      },
    },
    beforeRouteEnter(to, from, next) {
      return chain(
        {to, from, store},
        next,
        [
          ensureCheckAuth,
          ensureGetUser,
          ensureGetChannels,
          ({to}) => getPost(to.params.id),
          ({to}) => getBasicStats(to.params.id),
        ],
      )
    },
  }
</script>

<style scoped lang="stylus">
  mobile = "(max-width 599px)"

  .post
    @apply px-12 pt-3 pb-12

    @media mobile
      @apply px-6

  .post__content
    @apply pt-3

  .post__cards
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-template-areas "appearance state basic-stats" "stats stats stats"
    grid-column-gap 15px
    grid-row-gap 15px

    @media (max-width 1000px)
      grid-template-columns repeat(2, 1fr)
      grid-template-areas "appearance appearance" "state basic-stats" "stats stats"

    @media (max-width 600px)
      grid-template-columns 1fr
      grid-template-areas "appearance" "state" "basic-stats" "stats"

    .appearance
      grid-area appearance

    .state
      grid-area state

    .basic-stats
      grid-area basic-stats

      @apply relative

      .overlay
        @apply absolute inset-0
        background-color #ffffffc0

        @apply flex flex-col justify-center items-center
        font-weight 500

    .table
      @apply m-0

      tr
        @apply border-t border-gray-300

      td
        @apply p-3

        &:not(:last-child)
          @apply border-r border-gray-300

    .state-table
      .toggle-button
        @apply p-0

    .state-table, .basic-stats
      @apply w-full

  .post__export-preview
    @apply border-solid border-gray-300 border rounded px-4 py-2 mb-1
    overflow hidden
    max-width 250px
    text-overflow ellipsis
    user-select all
</style>
