<template>
  <div
    class="post-card"
    :class="{'bordered': !noBorder}">
    <slot/>
  </div>
</template>

<script>
  export default {
    name: 'Card',
    props: {
      noBorder: Boolean,
    }
  }
</script>

<style scoped lang="stylus">
  .post-card
    @apply flex flex-col justify-between min-w-0 overflow-hidden

    &.bordered
      @apply border border-gray-300 rounded
      @apply bg-white
</style>