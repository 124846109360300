<template>
  <div class="header-row">
    <h4>{{title}}</h4>
    <slot/>
  </div>
</template>

<script>
  export default {
    name: 'CardHeader',
    props: {
      title: String,
    }
  }
</script>

<style scoped lang="stylus">
  .header-row
    @apply flex flex-row justify-between items-baseline p-4 pb-0 mb-4
    h4
      @apply m-0
</style>